import * as Yup from 'yup'
import { login } from './messages'

export default Yup.object().shape({
    raptrId: Yup.string().required(login.raptrId.required),
    password: Yup.string()
        .required(login.password.required)
        .min(6, login.password.min)
        .max(100, login.password.max),
})
