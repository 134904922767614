import { Form, Formik } from 'formik'
import { useCallback, useMemo, useState } from 'react'

import Alert from '@/components/base/Alert'
import Button from '@/components/base/Button'
import TextBox from '@/components/base/TextBox'

import { useSession } from '@/contexts/Session'
import loginNew from '@/validations/loginNew'

const LoginForm = () => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)

    const { setAuthToken } = useSession()

    const initialValues = useMemo(
        () => ({
            raptrId: '',
            password: '',
        }),
        []
    )

    const handleSubmit = useCallback(
        async ({ raptrId, password }, { setSubmitting }) => {
            setSubmitting(false)
            setLoading(true)

            const authLoginUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login-user`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    raptrId,
                    password,
                    permission: 'admin-login',
                }),
            }

            const response = await fetch(authLoginUrl, fetchOptions)

            const data = await response.json()

            if (response.ok) {
                setSuccessMessage(data.message)
                setErrorMessage(null)
                setAuthToken('accessToken', data?.data?.accessToken, {
                    path: '/',
                    sameSite: true,
                })

                setAuthToken('refreshToken', data?.data?.refreshToken, {
                    path: '/',
                    sameSite: true,
                })
            } else {
                setErrorMessage(data.message)
            }

            setLoading(false)
        },
        [setAuthToken, setErrorMessage, setLoading, setSuccessMessage]
    )

    return (
        <div className="relative z-[9] p-[42px]  ipad:px-[20px] ipad:py-[24px]">
            <h3 className="mb-3 text-center text-xl ipad:mb-[6px]">
                Log in to your account
            </h3>
            <Formik
                initialValues={initialValues}
                validationSchema={loginNew}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ errors, touched }) => {
                    const firstKey = Object.keys(errors)[0]
                    const showError = !!errors[firstKey] && !!touched[firstKey]

                    return (
                        <Form noValidate>
                            <TextBox
                                id="raptrId"
                                name="raptrId"
                                type="text"
                                size="lg"
                                variant="medium"
                                placeholder="Enter Your RaptrId"
                            />
                            <TextBox
                                id="password"
                                name="password"
                                type="password"
                                size="lg"
                                variant="medium"
                                placeholder="Enter Your Password"
                            />
                            {(showError || errorMessage) && (
                                <div className="mb-4  max-w-[768px]">
                                    <Alert
                                        size="sm"
                                        variant="danger"
                                        message={
                                            errorMessage || errors[firstKey]
                                        }
                                        icon="inactive"
                                        iconStyle="text-common-white pr-2 relative top-[1px]"
                                        noSpace
                                    ></Alert>
                                </div>
                            )}
                            {successMessage && (
                                <div className="mt-6">
                                    <Alert
                                        size="sm"
                                        variant="success"
                                        message={successMessage}
                                        icon="check"
                                        iconStyle="text-common-white pr-2 text-lg relative top-[1px]"
                                    ></Alert>
                                </div>
                            )}
                            <Button
                                type="submit"
                                variant="primary"
                                text="Login"
                                size="md"
                                modification="w-full mb-6 ipad:mb-0"
                                loading={loading}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default LoginForm
