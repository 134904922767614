/* eslint-disable max-lines */
const login = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    verificationCode: {
        required: 'OTP field is required.',
        min: 'OTP must have 6 characters.',
    },
    raptrId: {
        required: 'Raptr ID field is required.',
    },
    password: {
        required: 'Password field is required.',
        min: 'Password must be at least 6 characters.',
        max: 'Password should not be greater than 100 characters.',
    },
}

const systemUser = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
    },
    displayName: {
        required: 'Full name field is required.',
        min: 'Full name must be at least 2 characters.',
        max: 'Full name must be at most 100 characters.',
    },
}

const configurations = {
    raptrServiceCharge: {
        required: 'Service charge percentage field is required.',
        min: 'Service charge percentage min value is 0.',
        max: 'Service charge percentage max value is 100.',
        matches:
            'Service charge field must be numeric and may contain only two decimal places.',
    },
    maxTip: {
        required: 'Maximum tip field is required.',
        min: 'Maximum tip min value is 1.',
        max: 'Maximum tip should not be greater than 9 digits.',
        matches:
            'Maximum tip field must be numeric and may contain only two decimal places.',
    },
    tipSlotDifference: {
        required: 'Difference field is required.',
        min: 'Difference min value is 0.5.',
        max: 'Difference max value is 100.',
        matches:
            'Maximum tip field must be numeric and may contain only two decimal places.',
    },
    minTip: {
        required: 'Minimum tip field is required.',
        min: 'Minimum tip min value is 0.',
        max: 'Minimum tip should not be greater than 9 digits.',
        matches:
            'Minimum tip field must be numeric and may contain only two decimal places.',
    },
    supportEmail: {
        required: 'Support field is required.',
        invalid: 'Please enter a valid support email address.',
    },
}

const category = {
    title: {
        required: 'Title field is required.',
        min: 'Title must be at least 2 characters.',
        max: 'Title must be at most 100 characters.',
    },
    description: {
        required: 'Short description is required.',
        min: 'Short description must be at least 2 characters.',
        max: 'Short description must be at most 500 characters.',
    },
    tags: { required: 'At least one tag is required.' },
    coverPhoto: { required: 'Cover photo field is required.' },
}

const tournamentType = {
    title: {
        required: 'Title field is required.',
        min: 'Title must be at least 2 characters.',
        max: 'Title must be at most 100 characters.',
    },
    audienceId: {
        required: 'Audience Id is required.',
        min: 'Audience Id must be at least 2 characters.',
        max: 'Audience Id must be at most 500 characters.',
    },
}

const tournament = {
    title: {
        required: 'Title field is required.',
        min: 'Title must be at least 2 characters.',
        max: 'Title must be at most 100 characters.',
    },
    description: {
        required: 'Description field is required.',
        min: 'Description must be at least 2 characters.',
        max: 'Description must be at most 500 characters.',
    },
    tournamentTypeId: {
        required: 'Tournament type field is required.',
    },
    tournamentGameId: {
        required: 'Tournament game field is required.',
    },
    teamBased: {
        required: 'Team based field is required.',
    },
    startDate: {
        required: 'Start date field is required.',
    },
    endDate: {
        required: 'End date field is required.',
    },
    backgroundImage: {
        required: 'Background image field is required.',
    },
}
const tournamentParticipantPosition = {
    position: {
        required: 'Position field is required.',
    },
}

const giveaway = {
    title: {
        required: 'Title field is required.',
        min: 'Title must be at least 2 characters.',
        max: 'Title must be at most 100 characters.',
    },
    startDate: {
        required: 'Start date field is required.',
    },
    endDate: {
        required: 'End date field is required.',
    },
    backgroundImage: {
        required: 'Background image field is required.',
    },
}

const streaming = {
    title: {
        required: 'Title field is required.',
    },
    shortDescription: { required: 'Short description is required.' },

    tags: { required: 'Select tags field is required.' },
}

const sticker = {
    stickers: { required: 'At least one sticker is required.' },
}

const dashboardVideo = {
    link: {
        required: 'Link field is required.',
        min: 'Link must be at least 1 characters.',
    },
    order: {
        required: 'Order field is required.',
    },
}

const dashboardVideoType = {
    title: {
        required: 'Link field is required.',
        min: 'Link must be at least 1 characters.',
    },
}

export {
    category,
    configurations,
    dashboardVideo,
    dashboardVideoType,
    giveaway,
    login,
    sticker,
    streaming,
    systemUser,
    tournament,
    tournamentParticipantPosition,
    tournamentType,
}
